<template>
<div style="width:100%">
<v-sheet :color="colores.primario" style="padding:1px" rounded>
    <v-card flat>
      <v-toolbar dense flat v-if="item.usuario">
        <span :style="{'color': colores.primario}" style="font-size:15px"><v-icon :color="colores.primario">mdi-account</v-icon>{{ item.usuario.nombre_usuario + ' ' + item.usuario.apellido_usuario }}</span>
        <v-spacer></v-spacer>
        <span :style="{'color': colores.primario}" style="font-size:15px"><v-icon :color="colores.primario">mdi-calendar</v-icon>{{ rec_function_fecha_colombia(item.created_at) }}</span>
        <!-- <v-btn @click="abrirNotificacion" icon><v-icon :color="colores.primario">mdi-email</v-icon></v-btn> -->
      </v-toolbar>
      <v-card-text class="pt-0" v-if="item.id">
        <v-row>
          <v-col cols="12" md="1" align-self="center" class="text-center">
            <v-avatar :color="colores.primario" size="40" class="white--text">{{ index + 1 }}</v-avatar>
          </v-col>
          <v-col cols="12" md="11">
            <v-row no-gutters>
              <v-col cols="12">
                {{ item.descripcion }}
              </v-col>
            </v-row>
            <br>
            <v-row no-gutters>
              <v-col cols="12"></v-col>
            </v-row>
            <v-row no-gutters v-if="item.gesesclogs">
              <v-col cols="12">
                  <v-card v-for="(itemEsc, indexEsc) in item.gesesclogs" :key="indexEsc" class="mb-2">
                    <v-card-text>
                      <logsEscalamientos :indexEsc="indexEsc" :itemEsc="itemEsc" :colores="colores"></logsEscalamientos>
                    </v-card-text>
                  </v-card>
              </v-col>
            </v-row>
            <v-row no-gutters v-if="item.gesotro">
              <v-col cols="12">
                <v-card>
                    <v-card-text>
                      <logsEscalamientosOtro :item="item.gesotro" :colores="colores"></logsEscalamientosOtro>
                    </v-card-text>
                  </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <br>
        <v-divider></v-divider>
      </v-card-text>
    </v-card>
  <formNotificaciones :dialogoMail="dialogoMail" :colores="colores" @closedNotificacion="cerrarNotificacion"></formNotificaciones></v-sheet>
</div>
</template>

<script>
// import formNotificaciones from '@/components/crm/form_notificacionesComponent.vue'
// import logsEscalamientos from '@/components/crm/lectura_logs_escalamientosComponent.vue'
// import logsEscalamientosOtro from '@/components/crm/lectura_logs_escalamientos_otroComponent.vue'

import recFunciones from '@/js/rec_funciones.js'
export default {
  name: 'lectura_logsComponent',
  components: {
    formNotificaciones: () => import('@/components/crm/form_notificacionesComponent.vue'),
    logsEscalamientos: () => import('@/components/crm/lectura_logs_escalamientosComponent.vue'),
    logsEscalamientosOtro: () => import('@/components/crm/lectura_logs_escalamientos_otroComponent.vue')
  },
  mixins: [recFunciones],
  props: ['colores', 'item', 'index'],
  data: () => ({
    dialogoMail: false
  }),
  created () {

  },
  methods: {
    abrirNotificacion () {
      this.dialogoMail = true
    },
    cerrarNotificacion () {
      this.dialogoMail = false
    }

  }

}
</script>
